import React from "react";
import {
  ContactContainer,
  ContactHeading,
  ContactSection,
  ContactIcons,
  ContactH2,
  ContactWrapper,
  ContactIconLink,
} from "./ContactElements";
import { FiPhoneCall } from "react-icons/fi";
import { SiUbereats, SiGrubhub, SiDoordash } from "react-icons/si";

const Contact = ({ heading }) => {
  return (
    <ContactContainer id="contact">
      <ContactHeading>{heading}</ContactHeading>
      <ContactH2>
        Call us or order through your favorite app!
        <br />
        We offer free delivery within a half mile radius when you order by
        phone! <br />* $2.00 fee during harsh conditions *
      </ContactH2>
      <ContactSection>
        <ContactWrapper>
          <ContactIcons>
            <ContactIconLink href="tel:+16468613279" aria-label="Phone">
              <FiPhoneCall size={75} />
            </ContactIconLink>
          </ContactIcons>
          <ContactIcons>
            <ContactIconLink
              href="https://www.ubereats.com/store/fruces/_T3aUrA1QFqZKbOOdbyIiA"
              target="_blank"
              rel="noreferrer"
              aria-label="UberEats"
            >
              <SiUbereats size={75} />
            </ContactIconLink>
          </ContactIcons>
          <ContactIcons>
            <ContactIconLink
              href="https://www.grubhub.com/restaurant/fruces-juice-bar-1496-amsterdam-ave-new-york/994637"
              target="_blank"
              rel="noreferrer"
              aria-label="GrubHub"
            >
              <SiGrubhub size={75} />
            </ContactIconLink>
          </ContactIcons>
          <ContactIcons>
            <ContactIconLink
              href="https://www.doordash.com/store/534002?pickup=true"
              target="_blank"
              rel="noreferrer"
              aria-label="DoorDash"
            >
              <SiDoordash size={75} />
            </ContactIconLink>
          </ContactIcons>
        </ContactWrapper>
      </ContactSection>
      <iframe
        title="Fruces Google Maps"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.4442397176927!2d-73.95516868459174!3d40.818204979320534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f66f3042f479%3A0x172b4f8be062dba2!2sFruces%20Juice%20Bar!5e0!3m2!1sen!2sus!4v1638921978968!5m2!1sen!2sus"
        width="100%"
        height="300"
        loading="lazy"
      ></iframe>
    </ContactContainer>
  );
};

export default Contact;
