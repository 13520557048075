import React from "react";
import { FaYelp, FaFacebookSquare, FaInstagram } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterInfoWrapper,
  FooterItems,
  FootLinkTitle,
  FooterH2,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import Logo from "./FooterElements";
import logoImg from "../../images/fruces-logo-1.png";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterInfoWrapper>
          <FooterItems>
            <FootLinkTitle>Business Hours</FootLinkTitle>
            <FooterH2>Monday - Friday</FooterH2>
            8:00AM - 8:00PM
            <FooterH2>Saturday</FooterH2>
            8:00AM - 6:00PM
            <FooterH2>Sunday</FooterH2>
            Closed
          </FooterItems>
          <FooterItems>
            <FootLinkTitle>Location</FootLinkTitle>
            <a
              href="https://g.page/Fruces134?share"
              target="_blank"
              rel="noreferrer"
            >
              1496 Amsterdam Avenue <br />
              New York, NY <br />
              10031
            </a>
          </FooterItems>
          <FooterItems>
            <FootLinkTitle>Phone</FootLinkTitle>
            <a href="tel:+16468613279">
              <u>(646) 861 - 3279</u>
            </a>
          </FooterItems>
        </FooterInfoWrapper>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              <Logo alt="Fruces Round Logo" src={logoImg} />
            </SocialLogo>
            <WebsiteRights>
              Fruces LLC © {new Date().getFullYear()} All Rights Reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.instagram.com/fruces134/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.facebook.com/Fruces134"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookSquare />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.yelp.com/biz/fruces-new-york-2"
                target="_blank"
                rel="noreferrer"
                aria-label="Yelp"
              >
                <FaYelp />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
