import styled from "styled-components";

export const ContactContainer = styled.div`
  padding: 5rem calc((95vw - 1000px) / 2);
  background: #fff;
  color: #000;
`;

export const ContactHeading = styled.h1`
  content: "";
  display: block;
  margin: 0 auto;
  border-bottom: 2px solid #e1651f;
  left: 0;
  width: 20%;
  font-family: "Oleo Script Swash Caps", cursive;
  font-size: clamp(2rem, 2.5vw, 4rem);
  text-align: center;
  margin-bottom: 2rem;
`;

export const ContactSection = styled.section`
  text-align: center;
  margin-bottom: 2rem;
`;

export const ContactIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ContactH2 = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: clamp(1rem, 1vw, 2rem);
  margin-bottom: 2rem;
`;

export const ContactH3 = styled.h3``;

export const ContactWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 50px 80px;
    grid-template-rows: auto;
  }
`;

export const ContactIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-bottom: 1rem;
`;
