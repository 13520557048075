import React, { useState } from "react";
import Menu from "./menu";
import Categories from "./categories";
import { fullMenu as items } from "./data2";
import {
  FullMenuSection,
  LogoContainer,
  MenuTitle,
  Underline,
} from "./MenuElements";
import Logo from "./MenuElements";
import logoImg from "../../images/fruces-logo-1.png";

const allCategories = ["all", ...new Set(items.map((item) => item.category))];

const FullMenu = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("");
  const [categories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }

    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <FullMenuSection>
      <LogoContainer>
        <Logo src={logoImg} />
      </LogoContainer>
      <MenuTitle id="top">
        Menu
        <Underline />
      </MenuTitle>
      <Categories
        categories={categories}
        activeCategory={activeCategory}
        filterItems={filterItems}
      />

      <Menu items={menuItems} />
    </FullMenuSection>
  );
};

export default FullMenu;
