import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarLink
          to="menu"
          onClick={toggle}
          smooth={true}
          href="https://www.fruces.com/#menu"
        >
          Menu
        </SidebarLink>
        <SidebarLink
          to="testimonials"
          onClick={toggle}
          smooth={true}
          href="https://www.fruces.com/#testimonials"
        >
          Testimonials
        </SidebarLink>
        <SidebarLink
          to="contact"
          onClick={toggle}
          smooth={true}
          href="https://www.fruces.com/#contact"
        >
          Contact
        </SidebarLink>
      </SidebarMenu>
      <SideBtnWrap>
        <a
          href="https://www.ubereats.com/store/fruces/_T3aUrA1QFqZKbOOdbyIiA"
          target="_blank"
          rel="noreferrer"
        >
          <SidebarRoute>Order Now</SidebarRoute>
        </a>
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default Sidebar;
