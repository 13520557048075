import styled from "styled-components";
import ImgBg from "../../images/fruces-breakfast.jpg";

export const TestimonialsContainer = styled.div`
  color: #fff;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${ImgBg});
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 750px) {
    background: #ffffff;
    color: #000000;
  }
`;

export const TestimonialsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
`;

export const TestimonialsHeading = styled.h1`
  content: "";
  display: block;
  margin: 0 auto;
  border-bottom: 2px solid #e1651f;
  left: 0;
  width: 20%;
  font-family: "Oleo Script Swash Caps", cursive;
  font-size: clamp(2rem, 2.5vw, 4rem);
  text-align: center;
  margin-bottom: 1rem;
`;

export const TestimonialCard = styled.div`
  color: #000;
  margin: 1rem;
  line-height: 1.5;
  width: 300px;
`;

export const TestimonialInfo = styled.div`
  border-radius: 25px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
`;

export const Name = styled.h2`
  font-weight: bold;
  font-size: clamp(1.5rem, 1vw, 2.5rem);
  text-transform: capitalize;
`;

export const Review = styled.p`
  font-size: clamp(1rem, 1vw, 2rem);
`;
export const Time = styled.time`
  font-size: clamp(0.75rem, 1vw, 1rem);
`;

export const TestimonialsBtn = styled.button`
  font-size: clamp(1rem, 1vw, 1.5rem);
  padding: 1.5rem 2rem;
  border: none;
  border-radius: 48px;
  background: #e1651f;
  color: #fff;
  transition: 0.2s ease-out;
  text-align: center;
  margin-top: 1.5rem;
  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 650px) : {
    width: 100vw;
  }
`;
