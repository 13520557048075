import React from "react";
import {
  MenuCenter,
  MenuArticle,
  AllItemsInfo,
  ItemHeader,
  MenuH4,
  MenuPrice,
  ItemP,
  MenuSubcategory,
} from "./MenuElements";

const Menu = ({ items }) => {
  return (
    <MenuCenter>
      {items.map((item, idx) => {
        const { name, description, price, subcategory } = item;
        <MenuSubcategory>{subcategory}</MenuSubcategory>;
        return (
          <MenuArticle key={idx}>
            <AllItemsInfo>
              <ItemHeader>
                <MenuH4>{name}</MenuH4>
                <MenuPrice>${price}</MenuPrice>
              </ItemHeader>
              <ItemP>{description}</ItemP>
            </AllItemsInfo>
          </MenuArticle>
        );
      })}
    </MenuCenter>
  );
};

export default Menu;
