import React from "react";
import { Nav, NavLink, NavIcon, Bars } from "./NavbarElements";
import Logo from "./NavbarElements";
import logoImg from "../../images/fruces-logo-2.png";

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavLink to="/">
          <Logo alt="Fruces Logo" src={logoImg} />
        </NavLink>

        <NavIcon onClick={toggle}>
          <p></p>
          <Bars />
        </NavIcon>
      </Nav>
    </>
  );
};

export default Navbar;
