import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GlobalStyle } from "./globalStyles";
import Home from "./pages";
import Menu from "./pages/menuPage";
import ScrollToTop from "./pages/scrollToTop.js";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/menu" component={Menu} exact />
      </Switch>
    </Router>
  );
}

export default App;
