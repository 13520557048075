import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SideBtnWrap,
  SidebarRoute,
  SidebarRouterLink,
  SidebarLink,
} from "./SidebarElements";

const MenuSidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarRouterLink to="/">Home</SidebarRouterLink>
        <SidebarLink to="top" onClick={toggle} smooth={true}>
          Back To Top
        </SidebarLink>
      </SidebarMenu>
      <SideBtnWrap>
        <a
          href="https://www.ubereats.com/store/fruces/_T3aUrA1QFqZKbOOdbyIiA"
          target="_blank"
          rel="noreferrer"
        >
          <SidebarRoute>Order Now</SidebarRoute>
        </a>
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default MenuSidebar;
