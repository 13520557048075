import React, { useState, useEffect } from "react";
import StarRatingComponent from "react-star-rating-component";
// import axios from "axios";
import {
  TestimonialsWrapper,
  TestimonialCard,
  TestimonialInfo,
  Name,
  Review,
  Time,
} from "./TestimonialsElements";

const BASEURL =
  "/api/maps/api/place/details/json?placeid=ChIJefRCMG_2wokRotti4ItPKxc&key=";

const PLACES_KEY = `${process.env.REACT_APP_GOOGLE_PLACES_KEY}`;

const Reviews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = () => {
    fetch(BASEURL + PLACES_KEY, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response.result);
        setIsLoading(false);
      })
      .catch((error) => console.log("An Error Occured: " + error));
  };

  return (
    <TestimonialsWrapper>
      {isLoading && <p>Loading Reviews!</p>}
      {data.reviews?.map((review, index) => (
        <TestimonialCard key={index}>
          <TestimonialInfo>
            <Name>{review.rating >= 4 ? review.author_name : null}</Name>
            <StarRatingComponent
              name="rate"
              editing={false}
              starCount={5}
              value={review.rating >= 4 ? review.rating : null}
              emptyStarColor="#fff"
            />
            <Time>
              {review.rating >= 4 ? review.relative_time_description : null}
            </Time>
            <Review>{review.rating >= 4 ? review.text : null}</Review>
          </TestimonialInfo>
        </TestimonialCard>
      ))}
    </TestimonialsWrapper>
  );
};

export default Reviews;
