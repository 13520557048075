import styled from "styled-components";
import ImgBg from "../../images/fruces-shots.jpg";

export const HeroContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${ImgBg});
  height: 100vh;
  background-position: center;
  background-size: cover;
`;

export const HeroContent = styled.div`
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((95vw - 1000px) / 2);

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100%;
  padding: 0 2rem;
  color: #fff;
  text-transform: capitalize;
  line-height: 1;

  @media screen and (max-width: 650px) : {
    width: 100vw;
  }
`;

export const HeroH1 = styled.h1`
  font-family: "Oleo Script Swash Caps", cursive;
  font-size: clamp(2.5rem, 10vw, 5rem);
  margin-bottom: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
`;

export const HeroP = styled.p`
  font-size: clamp(1rem, 2vw, 2rem);
  margin-bottom: 3rem;
  text-align: center;
`;

export const HeroBtn = styled.button`
  font-size: clamp(1rem, 1vw, 1.5rem);
  padding: 1.5rem 2rem;
  border: none;
  border-radius: 48px;
  background: #e1651f;
  color: #fff;
  transition: 0.2s ease-out;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;
