import React from "react";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroItems>
          <HeroH1> Fruces Juice Bar </HeroH1>
          <br />
          <HeroP>
            {" "}
            <br /> 1496 Amsterdam Avenue
            <br /> New York, NY 10031
            <br />
            <br />
            <a href="tel:+16468613279">
              <u>(646) 861 - 3279</u>
            </a>
          </HeroP>

          <a
            href="https://www.ubereats.com/store/fruces/_T3aUrA1QFqZKbOOdbyIiA"
            target="_blank"
            rel="noreferrer"
          >
            <HeroBtn>Order Now</HeroBtn>
          </a>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
