import React from "react";
import {
  TestimonialsContainer,
  TestimonialsHeading,
  TestimonialsBtn,
  BtnWrapper,
} from "./TestimonialsElements";
import Reviews from "./reviews.js";

const Testimonials = ({ heading }) => {
  return (
    <TestimonialsContainer id="testimonials">
      <TestimonialsHeading>{heading}</TestimonialsHeading>
      <Reviews />
      <BtnWrapper>
        <a
          href="https://search.google.com/local/writereview?placeid=ChIJefRCMG_2wokRotti4ItPKxc"
          target="_blank"
          rel="noreferrer"
        >
          <TestimonialsBtn>Write A Review</TestimonialsBtn>
        </a>

        <a
          href="https://search.google.com/local/reviews?placeid=ChIJefRCMG_2wokRotti4ItPKxc"
          target="_blank"
          rel="noreferrer"
        >
          <TestimonialsBtn>View All Reviews</TestimonialsBtn>
        </a>
      </BtnWrapper>
    </TestimonialsContainer>
  );
};

export default Testimonials;
