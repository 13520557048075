import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const MenuContainer = styled.div`
  padding: 5rem calc((95vw - 1000px) / 2);
  background: #fff;
  color: #000;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const MenuItemHeading = styled.h1`
  content: "";
  display: block;
  margin: 0 auto;
  border-bottom: 2px solid #e1651f;
  left: 0;
  width: 20%;
  font-family: "Oleo Script Swash Caps", cursive;
  font-size: clamp(2rem, 2.5vw, 4rem);
  text-align: center;
  margin-bottom: 2rem;
`;

export const MenuItemCard = styled.div`
  margin: 0 2rem;
  line-height: 1.5;
  width: 300px;
`;

export const ItemInfo = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
`;

export const ItemName = styled.h2`
  font-weight: bold;
  font-size: clamp(1.25rem, 1vw, 2.5rem);
`;

export const ItemDesc = styled.p`
  margin-bottom: 1rem;
  font-size: clamp(1rem, 1vw, 2rem);
`;

export const ItemPrice = styled.p`
  margin-bottom: 1rem;
  font-size: clamp(1rem, 1vw, 2rem);
`;

export const MenuBtn = styled.button`
  display: block;
  margin: auto;
  max-width: 300px;
  font-size: clamp(1rem, 1vw, 1.5rem);
  padding: 1.5rem 2rem;
  border: none;
  border-radius: 48px;
  background: #e1651f;
  color: #fff;
  transition: 0.2s ease-out;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

export const FullMenuSection = styled.section`
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  margin-bottom: 6rem;
  @media screen and (min-width: 992px) {
    width: 95vw;
  }
`;

export const LogoContainer = styled.div`
  margin-top: 5rem;
  text-align: center;
`;

export const MenuTitle = styled.h1`
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Underline = styled.div`
  width: 6rem;
  height: 0.25rem;
  background: #c59d5f;
  margin-left: auto;
  margin-right: auto;
`;

export const BtnContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: flex-start;
    overflow-y: scroll;
    white-space: nowrap;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
`;

export const CategoryBtn = styled.button`
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: hsl(360, 71%, 66%);
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;

  &.active {
    border-bottom: 2px solid;
  }
`;

export const MenuCenter = styled.div`
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 2rem;
  justify-items: center;

  @media screen and (max-width: 700px) {
    display: block;
    justify-items: start;
    justify-content: center;
  }
`;

export const MenuArticle = styled.article`
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
`;

export const AllItemsInfo = styled.div``;

export const ItemHeader = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted hsl(210, 22%, 49%);
`;

export const MenuPrice = styled.h4`
  color: #c59d5f;
`;

export const SubcategoryContainer = styled.div``;
export const MenuSubcategory = styled.h2`
  margin-bottom: 0.5rem;
`;
export const MenuH4 = styled.h4`
  margin-bottom: 0.5rem;
`;

export const ItemP = styled.p`
  margin-bottom: 2rem;
  padding-top: 0.5rem;
`;

export const MenuLink = styled(LinkR)`
  color: inherit;
  text-decoration: none;
`;

const Logo = styled.img`
  width: auto;
  height: 200px;
  margin: auto;
`;

export default Logo;
