import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";
import Menu from "../components/Menu";
import { menuData } from "../components/Menu/data.js";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Hero />
      <Menu heading="Popular Items" data={menuData} />
      <Testimonials heading="Reviews" />
      <Contact heading="Contact"></Contact>
      <Footer />
    </>
  );
};

export default Home;
