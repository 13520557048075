import React from "react";
import { BtnContainer, CategoryBtn } from "./MenuElements";

const Categories = ({ categories, activeCategory, filterItems }) => {
  return (
    <BtnContainer>
      {categories.map((category, index) => {
        return (
          <CategoryBtn
            className={`${activeCategory === category ? "active" : ""}`}
            key={index}
            onClick={() => filterItems(category)}
          >
            {category}
          </CategoryBtn>
        );
      })}
    </BtnContainer>
  );
};

export default Categories;
