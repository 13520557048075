import React from "react";
import {
  MenuContainer,
  MenuItemWrapper,
  MenuItemHeading,
  MenuItemCard,
  ItemInfo,
  ItemName,
  ItemDesc,
  ItemPrice,
  MenuBtn,
  MenuLink,
} from "./MenuElements";

const Menu = ({ heading, data }) => {
  return (
    <MenuContainer id="menu">
      <MenuItemHeading>{heading}</MenuItemHeading>
      <MenuItemWrapper>
        {data.map((item, index) => {
          return (
            <MenuItemCard key={index}>
              {/* <ProductImg src={item.img} alt = {product.alt} /> */}
              <ItemInfo>
                <ItemName>{item.name}</ItemName>
                <ItemDesc>{item.desc}</ItemDesc>
                <ItemPrice>{item.price}</ItemPrice>
              </ItemInfo>
            </MenuItemCard>
          );
        })}
      </MenuItemWrapper>
      <br />

      <MenuLink to="/menu">
        <MenuBtn>Full Menu</MenuBtn>
      </MenuLink>
    </MenuContainer>
  );
};

export default Menu;
