export const fullMenu = [
  {
    name: "Quinoa Salad",
    description:
      "Mix greens, cherry tomatoes, cucumbers, carrot shaves, fennel, avocado, quinoa, lemon thyme dresing.",
    category: "Food",
    subcategory: "Salads",
    price: "10.00",
  },
  {
    name: "Chicken Salad",
    description:
      "Mix greens, cherry tomamtoes, carrot shaves, granny smith apple, cashews, feta cheese, sherry vinaigrette.",
    category: "Food",
    subcategory: "Salads",
    price: "12.00",
  },
  {
    name: "Arrugula Pistachio Salad",
    description:
      "Arrugula, orange, red pickled onions, pistachio, bell peppers, sherry vinaigrette.",
    category: "Food",
    subcategory: "Salads",
    price: "10.00",
  },
  {
    name: "Mediterranean Salad",
    description:
      "Olives, cucumber, tomato, feta cheese, bell peppers, red onions, lemon thyme dressing.",
    category: "Food",
    subcategory: "Salads",
    price: "10.00",
  },
  {
    name: "Beets Salad",
    description: "Kale & spinach mix, goat cheese, agave balsamic.",
    category: "Food",
    subcategory: "Salads",
    price: "10.00",
  },
  {
    name: "PB Acai Bowl",
    description:
      "Granola, banana, strawberry, blueberry, crunchy peanut butter and chia or flax seeds.",
    category: "Bowls",
    subcategory: "Acai Bowls",
    price: "10.00",
  },
  {
    name: "Nutella Baby Bowl",
    description: "Granola, banana, strawberry, nutella, and coconut flakes.",
    category: "Bowls",
    subcategory: "Acai Bowls",
    price: "10.00",
  },
  {
    name: "Ultimate Acai Bowl",
    description:
      "Granola, Mango, Strawberry, Blueberry, Almond Butter, Coconut Flakes and Chia seeds.",
    category: "Bowls",
    subcategory: "Acai Bowls",
    price: "12.00",
  },
  {
    name: "Protein Buzz Bowl",
    description:
      "Omega 3 mix/granola, raspberry, pineapple, banana, flax seeds, chia seeds, and bee pollen.",
    category: "Bowls",
    subcategory: "Power Acai Bowls",
    price: "15.00",
  },
  {
    name: "Morning Buzz Bowl",
    description:
      "Omega 3 mix/granola, cacao nibs, hemp seeds, flax seeds, banana, and honey.",
    category: "Bowls",
    subcategory: "Power Acai Bowls",
    price: "15.00",
  },
  {
    name: "Dragon Berry Bowl",
    description:
      "Granola, kiwi, strawberry, blueberry, pineapple and almond butter.",
    category: "Bowls",
    subcategory: "Pitaya Bowls",
    price: "12.00",
  },
  {
    name: "Coco-Loko Bowl",
    description:
      "Granola, mango, strawberry, pineapple, Nutella and coconut flakes.",
    category: "Bowls",
    subcategory: "Pitaya Bowls",
    price: "12.00",
  },
  {
    name: "Green Power Bowl",
    description:
      "Granola, kiwi, strawberry, banana, crunchy peanut butter, honey, and hemp seeds.",
    category: "Bowls",
    subcategory: "Pitaya Bowls",
    price: "12.00",
  },
  {
    name: "Green Booster Bowl",
    description:
      "Omega 3 mix/granola, strawberry, raspberry, pineapple, bee pollen, and agave.",
    category: "Bowls",
    subcategory: "Pitaya Bowls",
    price: "12.00",
  },
  {
    name: "Create Your Own Bowl",
    description: "Acai base, choose up to 4 toppings.",
    category: "Bowls",
    subcategory: "Bowls",
    price: "12.00",
  },
  {
    name: "Wheatgrass",
    description:
      "Wheatgrass is an excellent source of vitamin A, C and E. As well as iron, magnesium, calcium, and amino acids. 1 shot equals 2.5 lbs of greens, gives instant energy.",
    category: "Health Shots",
    subcategory: "Health Shots",
    price: "3.00 - 10oz, $5.00 - 20oz",
  },
  {
    name: "Turmeric",
    description:
      "Turmeric is a potent anti-inflammatory and antioxidant and may also help improve system of depression and arthritis. Also have many scientifically proven health benefits, such as the potential to prevent Heart disease, Alzheimer's and cancer.",
    category: "Health Shots",
    subcategory: "Health Shots",
    price: "3.00 - 10oz, $5.00 - 20oz",
  },
  {
    name: "Immune Shot",
    description:
      "Turmeric, Ginger, Lemon and Cayenne Pepper. Boost your immune system!",
    category: "Health Shots",
    subcategory: "Health Shots",
    price: "3.00 - 10oz, $5.00 - 20oz",
  },
  {
    name: "Ginger Shot",
    description: "",
    category: "Health Shots",
    subcategory: "Health Shots",
    price: "3.00 - 10oz, $5.00 - 20oz",
  },
  {
    name: "Avocado Toast",
    description:
      " 2pcs of Multigrain Toast, Avocado Spread, Red Pickleds Onions, Fresh Dill, Your choice from: Roasted Peppers, Boiled Eggs, Smoked Salmon (+$6.00).",
    category: "Food",
    subcategory: "Sandwiches",
    price: "10.00",
  },
  {
    name: "Chicken Sandwich",
    description:
      "Your choice from Ciabatta or Multigrain toast, grilled chicken breast, spinach pesto, fresh mozzarella, roasted peppers.",
    category: "Food",
    subcategory: "Sandwiches",
    price: "12.00",
  },
  {
    name: "Chipotle Chicken Wrap",
    description:
      "Grilled chicken breast, romaine lettuce, avocado, red pickled onion, chipotle mayo.",
    category: "Food",
    subcategory: "Sandwiches",
    price: "10.00",
  },
  {
    name: "Tuna Salad Sandwich",
    description:
      "Your choice from Ciabatta or Multigrain toast, tuna salad, romaine lettuce, tomato, provolone cheese.",
    category: "Food",
    subcategory: "Sandwiches",
    price: "10.00",
  },
  {
    name: "Cheese Samosa",
    description: "Queso tropical (white cheese).",
    category: "Food",
    subcategory: "Samosas",
    price: "2.50",
  },
  {
    name: "Chicken Samosa",
    description: "Chicken Samosa.",
    category: "Food",
    subcategory: "Samosas",
    price: "2.50",
  },
  {
    name: "Lentil Samosa",
    description: "Lentil Samosa",
    category: "Food",
    subcategory: "Samosas",
    price: "2.50",
  },
  {
    name: "Berry Oats Oatmeal",
    description: "Banana, bluebery, strawbery, honey.",
    category: "Food",
    subcategory: "Hot oatmeal",
    price: "6.00",
  },
  {
    name: "Honey Nuts Oatmeal",
    description: "Granola, mix nuts, cinnamon, honey.",
    category: "Food",
    subcategory: "Hot oatmeal",
    price: "6.00",
  },
  {
    name: "Apple Crisp Oatmeal",
    description: "Granola, peanut, butter, apple.",
    category: "Food",
    subcategory: "Hot oatmeal",
    price: "6.00",
  },
  {
    name: "Berry Tropical",
    description: "Blueberry, strawberry, pineapple, mango, banana, and orange.",
    category: "Smoothies",
    subcategory: "Fruit Smoothies",
    price: "8.50",
  },
  {
    name: "Pink Strawberry",
    description: "Strawberry, banana, carnation milk and organic agave.",
    category: "Smoothies",
    subcategory: "Fruit Smoothies",
    price: "8.50",
  },
  {
    name: "Kiwi Burst",
    description: "Kiwi, strawberry, apple, and orange.",
    category: "Smoothies",
    subcategory: "Fruit Smoothies",
    price: "8.50",
  },
  {
    name: "Sweet Passion",
    description: "Passion fruit, strawberry, mango, and pineapple.",
    category: "Smoothies",
    subcategory: "Fruit Smoothies",
    price: "8.50",
  },
  {
    name: "Mangoneada",
    description: "Mango, pineapple, chamoy, tajin, and tamarind candy stick.",
    category: "Smoothies",
    subcategory: "Fruit Smoothies",
    price: "8.50",
  },
  {
    name: "Fruity Kale",
    description: "Strawberry, peach, mango, banana, kale, and green apple.",
    category: "Smoothies",
    subcategory: "Green Smoothies",
    price: "8.50",
  },
  {
    name: "Sweet Green",
    description: "Mango, pineapple, spinach, kale, and almond milk.",
    category: "Smoothies",
    subcategory: "Green Smoothies",
    price: "8.50",
  },
  {
    name: "Smooth Avocado",
    description: "Avocado, banana, spinach, and apple.",
    category: "Smoothies",
    subcategory: "Green Smoothies",
    price: "8.50",
  },
  {
    name: "Morning Buzz",
    description:
      "Peanut butter, banana, honey, cacao nibs, oats, and almond milk.",
    category: "Smoothies",
    subcategory: "Protein Smoothies",
    price: "8.50",
  },
  {
    name: "All Whey",
    description:
      "Banana, almond butter, raw almonds, almond milk, and and whey protein.",
    category: "Smoothies",
    subcategory: "Protein Smoothies",
    price: "8.50",
  },
  {
    name: "Blue Vitality",
    description:
      "Acai, blueberry, banana, almond milk and vegan vanilla protein.",
    category: "Smoothies",
    subcategory: "Protein Smoothies",
    price: "8.50",
  },
  {
    name: "Bulldozer",
    description:
      "Strawberry, banana, crunchy peanut butter, chocolate vegan protein, and almond milk.",
    category: "Smoothies",
    subcategory: "Protein Smoothies",
    price: "8.50",
  },
  {
    name: "B-Powerful",
    description:
      "Blueberry, banana, crunchy peanut butter, vegan vanilla protein, and almond milk.",
    category: "Smoothies",
    subcategory: "Protein Smoothies",
    price: "8.50",
  },
  {
    name: "Green Supreme Juice",
    description:
      "Kale, spinach, parsley,  green apple, cucumber, ginger, lime.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "The Hulk Juice",
    description: "Spinach, parsley, celery, apple, ginger, lemon.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Natural Glow",
    description: "Kale, celery, green apple, pineapple, aloe vera.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Slim Green",
    description: "Kale, cucumber, celery, ginger, lime.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Essential Boost",
    description: "Kiwi, orange, apple, carrot, chia seeds.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Spicy Detox",
    description: "Ginger, lemon, cayenne pepper, apple, carrot, cucumber.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Heart Beet",
    description: "Beets, spinach, parsley, carrot, apple.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "T-Miracle",
    description: "Turmeric, parsley, celery, apple, pineapple.",
    category: "Juices",
    subcategory: "Natural Juices",
    price: "8.50",
  },
  {
    name: "Fresh Hot Ginger Tea",
    description: "Fresh ginger, apple, pineapple, honey.",
    category: "Hot Drinks & More",
    subcategory: "Hot Teas",
    price: "6.50",
  },
  {
    name: "Coconut water",
    description: "",
    category: "Hot Drinks & More",
    subcategory: "Bottled/ Canned Drinks",
    price: "2.00 - $4.00",
  },
  {
    name: "Kombucha",
    description: "",
    category: "Hot Drinks & More",
    subcategory: "Bottled/ Canned Drinks",
    price: "4.50",
  },
  {
    name: "LaCombe Coffee",
    description: "",
    category: "Hot Drinks & More",
    subcategory: "Bottled/ Canned Drinks",
    price: "3.50",
  },
  {
    name: "Red Bull",
    description: "",
    category: "Hot Drinks & More",
    subcategory: "Bottled/ Canned Drinks",
    price: "2.50",
  },
];
