import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.footer`
  padding: 0.5rem calc((95vw - 1500px) / 2);
  background-color: #000;
`;

export const FooterWrap = styled.div`
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
`;

export const FooterInfoWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  color: #fff;
  width: 100vw;
  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterItems = styled.span`
  text-align: center;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FootLinkTitle = styled.h1`
  font-weight: bold;
  font-size: clamp(1.5rem, 1vw, 2.5rem);
  margin-bottom: 16px;
`;

export const FooterH2 = styled.h2`
font-weight: bold;
font-size: clamp(1rem, 1vw, 2rem);
color: #fff
margin-bottom: 1rem;

`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100vw;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(LinkR)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

const Logo = styled.img`
  width: auto;
  height: 70px;
  margin: auto;
`;

export default Logo;
