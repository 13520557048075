import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

export const Nav = styled.nav`
  background: white;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const NavLink = styled(Link)`
  color: #fff;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 400px) {
    position: absolute;
    top: 10px;
    left: 25px;
  }
`;

export const NavIcon = styled.div`
  display: block;
  position: absolute;
  top: 30px;
  right: 0;
  cursor: pointer;
  color: black;

  p {
    transform: translate(-175%, 100%);
    font-weight: bold;
  }
`;

export const Bars = styled(GiHamburgerMenu)`
  font-size: 2rem;
  transform: translate(-50%, -15%);
`;

const Logo = styled.img`
  width: auto;
  height: 60px;
  margin: auto;
`;

export default Logo;
