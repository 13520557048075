export const menuData = [
  {
    name: "Ultimate Acai Bowl",
    desc: "Granola, Mango, Strawberry, Blueberry, Almond Butter, Coconut Flakes and Chia seeds.",
    price: "12.00",
  },
  {
    name: "PB Acai Bowl",
    desc: "Granola, banana, strawberry, blueberry, crunchy peanut butter and chia or flax seeds.",
    price: "10.00",
  },
  {
    name: "Chicken Sandwich",
    desc: "Your choice from Ciabatta or Multigrain toast, grilled chicken breast, spinach pesto, fresh mozzarella, roasted peppers.",
    price: "12.00",
  },
  {
    name: "Chicken Salad",
    desc: "Mix greens, cherry tomamtoes, carrot shaves, granny smith apple, cashews, feta cheese, sherry vinaigrette.",
    price: "12.00",
  },
  {
    name: "Morning Buzz Bowl",
    desc: "Omega 3 mix/granola, cacao nibs, hemp seeds, flax seeds, banana, and honey.",
    price: "15.00",
  },
  {
    name: "Quinoa Salad",
    desc: "Mix greens, cherry tomatoes, cucumbers, carrot shaves, fennel, avocado, quinoa, lemon thyme dresing.",
    price: "10.00",
  },
];
