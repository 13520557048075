import React, { useState } from "react";
import Navbar from "../components/Navbar";
import MenuSidebar from "../components/Sidebar/menuSidebar";
import Footer from "../components/Footer";
import FullMenu from "../components/Menu/fullMenu";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      <MenuSidebar isOpen={isOpen} toggle={toggle} />
      <FullMenu />
      <Footer />
    </>
  );
};

export default Menu;
